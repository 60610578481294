import React from "react";
import styles from '../CSS/Footer.module.css';
import whiteInstagram from '../Pages/Media/white instagrampng.png';
import whiteYoutube from '../Pages/Media/white youtube.webp';
import whiteDiscord from '../Pages/Media/white discord.png';
import whiteTikTok from '../Pages/Media/white tiktok.webp';

const Footer = () => {
    return (
        <section id={styles.socialsBar}>
            <a className={styles.socialLink} href="https://www.instagram.com/reanimated.au/" target="_blank" rel="noopener noreferrer">
                <img className={styles.logo} src={whiteInstagram} alt="Instagram Logo"></img>
            </a>
            <br></br>
            <a className={styles.socialLink} href="https://youtube.com/@reanimated_au?si=GMv8dJqHDoW3pCqH" target="_blank" rel="noopener noreferrer">
                <img className={styles.logo} src={whiteYoutube} alt="YoutTube Logo"></img>
            </a>
            <br></br>
            <a className={styles.socialLink} href="https://discord.gg/x6Z8xKXxqV" target="_blank" rel="noopener noreferrer">
                <img className={styles.logo} src={whiteDiscord} alt="Discord Logo"></img>
            </a>
            <br></br>
            <a className={styles.socialLink} href="https://www.tiktok.com/@reanimstation" target="_blank" rel="noopener noreferrer">
                <img className={styles.logo} src={whiteTikTok} alt="TikTok Logo"></img>
            </a>
        </section>
    );
};
 
export default Footer;