import React from "react";
import Navbar from '../Components/Navbar';
import styles from '../CSS/PreviousReanimated.module.css';
import banner from '../Pages/Media/batman banner.png';
import Footer from "../Components/Footer";

import screenCap0 from '../Pages/Media/Screencaps/Batman/batman (1).png'
import screenCap1 from '../Pages/Media/Screencaps/Batman/batman (2).png'
import screenCap2 from '../Pages/Media/Screencaps/Batman/batman (3).png'
import screenCap3 from '../Pages/Media/Screencaps/Batman/batman (5).png'
import screenCap4 from '../Pages/Media/Screencaps/Batman/batman (8).png'
import screenCap5 from '../Pages/Media/Screencaps/Batman/batman (10).png'
import screenCap6 from '../Pages/Media/Screencaps/Batman/batman (14).png'
import screenCap7 from '../Pages/Media/Screencaps/Batman/batman (17).png'

const Batman = () => {
    // document.body.style = 'background: radial-gradient(#d2adff, 5%, #9075F6);';

    return(
        <>
            <Navbar/>

            <img id={styles.banner} src={banner} alt="Banner for batman reanimated" draggable='false'></img>
            <iframe className={styles.video} id={styles.batmanVid} src="https://www.youtube.com/embed/vTJlDi3ikLs?si=lVtq5GpvmmWnn91J" title="Batman Reanimated" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

            <h1 id={styles.heading}>Animators</h1>
            <table>
                <tbody>
                    <tr><td className={styles.creditName}>Wilton Lander</td><td className={styles.creditSocial}>@weivy1</td></tr>
                    <tr><td className={styles.creditName}>Anna Nguyen </td><td className={styles.creditSocial}>@annapnguyen</td></tr>
                    <tr><td className={styles.creditName}>Gabby Hawkey</td><td className={styles.creditSocial}>@phoneboook</td></tr>
                    <tr><td className={styles.creditName}>Kate Darwell</td><td className={styles.creditSocial}>@wiile.s</td></tr>
                    <tr><td className={styles.creditName}>Lachlan Garrity</td><td className={styles.creditSocial}>@lachlangarrity</td></tr>
                    <tr><td className={styles.creditName}>Alyssa Mullen</td><td className={styles.creditSocial}>@heygremlin</td></tr>
                    <tr><td className={styles.creditName}>Jasmine Rogers</td><td className={styles.creditSocial}>@nap_culture</td></tr>
                    <tr><td className={styles.creditName}>Serena Hor</td><td className={styles.creditSocial}>@deafrenitely</td></tr>
                    <tr><td className={styles.creditName}>Haidi Yan</td><td className={styles.creditSocial}>@sleepistar</td></tr>
                    <tr><td className={styles.creditName}>David Muir</td><td className={styles.creditSocial}>@davidsolm_art</td></tr>
                    <tr><td className={styles.creditName}>Billy Rout</td><td className={styles.creditSocial}>@billybobjr.thethird</td></tr>
                    <tr><td className={styles.creditName}>Daisy Allen</td><td className={styles.creditSocial}>@daisymaisysart</td></tr>
                    <tr><td className={styles.creditName}>Luis Lagman</td><td className={styles.creditSocial}>@pio_hag</td></tr>
                    <tr><td className={styles.creditName}>Lara Colley</td><td className={styles.creditSocial}>@laracolley_28</td></tr>
                    <tr><td className={styles.creditName}>Jihee Park</td><td className={styles.creditSocial}>@heeyan_illustrations</td></tr>
                    <tr><td className={styles.creditName}>Tony Chen</td><td className={styles.creditSocial}>&nbsp;</td></tr>
                    <tr><td className={styles.creditName}>Jasmine Lai</td><td className={styles.creditSocial}>&nbsp;</td></tr>
                    <tr><td className={styles.creditName}>Justin Hsieh</td><td className={styles.creditSocial}>@justeeiin</td></tr>
                    <tr><td className={styles.creditName}>Cindy Zhi</td><td className={styles.creditSocial}>@pigeoncindy_</td></tr>
                    <tr><td className={styles.creditName}>Atticus Gough</td><td className={styles.creditSocial}>@atti.ink</td></tr>
                    <tr><td className={styles.creditName}>Aurora Liljestrand</td><td className={styles.creditSocial}>@joyjitterz</td></tr>
                    <tr><td className={styles.creditName}>May Fletcher</td><td className={styles.creditSocial}>@mawok</td></tr>
                    <tr><td className={styles.creditName}>Ruben Magloire</td><td className={styles.creditSocial}>@ruben.mglr</td></tr>
                    <tr><td className={styles.creditName}>Brandon Pen</td><td className={styles.creditSocial}>@armisaelani</td></tr>
                    <tr><td className={styles.creditName}>April Aki Clayton</td><td className={styles.creditSocial}>@skullofstatic</td></tr>
                    <tr><td className={styles.creditName}>William Zeng</td><td className={styles.creditSocial}>@williamzxng</td></tr>
                    <tr><td className={styles.creditName}>Miles Wright</td><td className={styles.creditSocial}>@milesmazzocato</td></tr>
                    <tr><td className={styles.creditName}>Han Nguyen</td><td className={styles.creditSocial}>@aeitchen</td></tr>
                    <tr><td className={styles.creditName}>Maia de Graaff</td><td className={styles.creditSocial}>@anotherfreakingartist</td></tr>
                    <tr><td className={styles.creditName}>Caroline Huang</td><td className={styles.creditSocial}>@daily_crispy</td></tr>
                    <tr><td className={styles.creditName}>Karen Ekadjaja</td><td className={styles.creditSocial}>@bertzieblu</td></tr>
                    <tr><td className={styles.creditName}>Julian Chang</td><td className={styles.creditSocial}>&nbsp;</td></tr>
                    <tr><td className={styles.creditName}>Jasper Ly</td><td className={styles.creditSocial}>@japerderp</td></tr>
                    <tr><td className={styles.creditName}>Marceline English</td><td className={styles.creditSocial}>@buddedlily</td></tr>
                    <tr><td className={styles.creditName}>Marshella Gohan</td><td className={styles.creditSocial}>&nbsp;</td></tr>
                    <tr><td className={styles.creditName}>Shanna Yang</td><td className={styles.creditSocial}>@shannanimation_bone</td></tr>
                    <tr><td className={styles.creditName}>Hollie Secomb</td><td className={styles.creditSocial}>@8nightlight8</td></tr>
                    <tr><td className={styles.creditName}>Alex Banks</td><td className={styles.creditSocial}>@captain_omelette</td></tr>
                    <tr><td className={styles.creditName}>Zachary Zitis</td><td className={styles.creditSocial}>&nbsp;</td></tr>
                    <tr><td className={styles.creditName}>Remi Evans</td><td className={styles.creditSocial}>@remrose_anim</td></tr>
                    <tr><td className={styles.creditName}>Cameron Qayoom-Taylor</td><td className={styles.creditSocial}>@Camari.Art</td></tr>
                    <tr><td className={styles.creditName}>Billy Bright</td><td className={styles.creditSocial}>@billybright_art</td></tr>
                    <tr><td className={styles.creditName}>Austen Armstrong</td><td className={styles.creditSocial}>&nbsp;</td></tr>
                    <tr><td className={styles.creditName}>Liana Duong</td><td className={styles.creditSocial}>@2d_trash</td></tr>
                    <tr><td className={styles.creditName}>Emily Hestelow </td><td className={styles.creditSocial}>@emily_hestelow</td></tr>
                    <tr><td className={styles.creditName}>hoi yie vivien chiu</td><td className={styles.creditSocial}>@livelyrunaway</td></tr>
                    <tr><td className={styles.creditName}>Nusha Amigh Wheeldon</td><td className={styles.creditSocial}>@yeah_nyah</td></tr>
                    <tr><td className={styles.creditName}>Michelle Huynh</td><td className={styles.creditSocial}>@mihooin </td></tr>
                    <tr><td className={styles.creditName}>Levi George</td><td className={styles.creditSocial}>@levigeorge</td></tr>
                    <tr><td className={styles.creditName}>Jacob Taylor</td><td className={styles.creditSocial}>@jakemakesthings</td></tr>
                    <tr><td className={styles.creditName}>Amy Chen</td><td className={styles.creditSocial}>@amychenie</td></tr>
                    <tr><td className={styles.creditName}>Yuna Jang</td><td className={styles.creditSocial}>&nbsp;</td></tr>
                    <tr><td className={styles.creditName}>Fransisca Caitlyn Wijaya</td><td className={styles.creditSocial}>@nyolus_</td></tr>
                    <tr><td className={styles.creditName}>Tyler Patten</td><td className={styles.creditSocial}>@nettaprelyt</td></tr>
                    <tr><td className={styles.creditName}>Ray Hidayat</td><td className={styles.creditSocial}>&nbsp;</td></tr>
                    <tr><td className={styles.creditName}>Amira Stanbouli </td><td className={styles.creditSocial}>@amira_stanbouli</td></tr>
                    <tr><td className={styles.creditName}>Elana Kwong</td><td className={styles.creditSocial}>@nightpest</td></tr>
                    <tr><td className={styles.creditName}>Patrick Tran</td><td className={styles.creditSocial}>@arucerious</td></tr>
                    <tr><td className={styles.creditName}>Paddy O'Brien</td><td className={styles.creditSocial}>@dolphino_media</td></tr>
                    <tr><td className={styles.creditName}>Natalie Tran</td><td className={styles.creditSocial}>@_Ltreat</td></tr>
                    <tr><td className={styles.creditName}>Will Savage </td><td className={styles.creditSocial}>@artwithsavage</td></tr>
                    <tr><td className={styles.creditName}>Theo Dahlstrom</td><td className={styles.creditSocial}>@theo.s.d</td></tr>
                    <tr><td className={styles.creditName}>Eric Lieu</td><td className={styles.creditSocial}>@eric_lieu</td></tr>
                    <tr><td className={styles.creditName}>Julia van Oppen</td><td className={styles.creditSocial}>@lukalunar</td></tr>
                    <tr><td className={styles.creditName}>Hannah Kelly</td><td className={styles.creditSocial}>@stupidcupid_art</td></tr>
                    <tr><td className={styles.creditName}>Alexandra Preston</td><td className={styles.creditSocial}>@aprestonart</td></tr>
                    <tr><td className={styles.creditName}>Tak Stapleton</td><td className={styles.creditSocial}>takstapleton.com</td></tr>
                    <tr><td className={styles.creditName}>Brenda Jie</td><td className={styles.creditSocial}>@pnut.br</td></tr>
                    <tr><td className={styles.creditName}>Siann Lau </td><td className={styles.creditSocial}>@ladysiann</td></tr>
                    <tr><td className={styles.creditName}>Benjamin Meyer</td><td className={styles.creditSocial}>&nbsp;</td></tr>
                    <tr><td className={styles.creditName}>Henry Lund</td><td className={styles.creditSocial}>@henry_lund</td></tr>
                </tbody>
            </table>
            <div>
                {/*<img className={styles.screencapL} src={screenCap0} style={{bottom: "66%"}}></img>*/}
                <img className={styles.screencapR} src={screenCap1} style={{bottom: "58%"}}></img>
                <img className={styles.screencapL} src={screenCap2} style={{bottom: "50%"}}></img>
                <img className={styles.screencapR} src={screenCap3} style={{bottom: "42%"}}></img>
                <img className={styles.screencapL} src={screenCap4} style={{bottom: "34%"}}></img>
                <img className={styles.screencapR} src={screenCap5} style={{bottom: "26%"}}></img>
                <img className={styles.screencapL} src={screenCap6} style={{bottom: "18%"}}></img>
                <img className={styles.screencapR} src={screenCap7} style={{bottom: "10%"}}></img>
            </div>

            <Footer/>
        </>
    )
}

export default Batman;