import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './Pages/index';
import About from './Pages/about';
import JapaneseSpiderman from './Pages/JapaneseSpiderman';
import Batman from './Pages/Batman';
import Monkey from './Pages/Monkey';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path='/Batman' element={<Batman />} />
        <Route path='/JapaneseSpiderman' element={<JapaneseSpiderman />} />
        <Route path='/Monkey' element={<Monkey />} />
      </Routes>
    </Router>
  );
}

export default App;
