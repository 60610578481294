import React from "react";
import Navbar from '../Components/Navbar';
import stylesMonkey from '../CSS/Monkey.module.css';
import styles from '../CSS/PreviousReanimated.module.css';
import banner from '../Pages/Media/MonkeyReanim_LOGO.png';
import qrCode from '../Pages/Media/qr placeholder.jpg';
import Footer from "../Components/Footer";
import gangImg from './/Media/gang1.png';
import catFishImg from './/Media/catfish.png';
import volcano from './/Media/bg1.png';
import poster from './/Media/monkeyreanim_screening_WIDE.png';

import screenCap0 from '../Pages/Media/Screencaps/Monkey/S104_Kenilem.png'
import screenCap1 from '../Pages/Media/Screencaps/Monkey/S125_EugeneChung.png'
import screenCap2 from '../Pages/Media/Screencaps/Monkey/S137_SiannLau.png'
import screenCap3 from '../Pages/Media/Screencaps/Monkey/S209_BrycePemberton.png'
import screenCap4 from '../Pages/Media/Screencaps/Monkey/S306_RicardoGomez.png'
import screenCap5 from '../Pages/Media/Screencaps/Monkey/S314_HanNguyen.png'
import screenCap6 from '../Pages/Media/Screencaps/Monkey/S412_LuisLagman.png'
import screenCap7 from '../Pages/Media/Screencaps/Monkey/S430_DiogoAlves.png'

const Monkey = () => { return(
        <>
            <Navbar/>

            <img id={styles.banner} src={banner} alt="Banner for monkey reanimated" draggable='false'></img>

            <iframe className={styles.video} id={styles.spiderVid} src="https://www.youtube.com/embed/hvQG_62W3wM?si=lrLt9jW--_UHplk2" title="Monkey Reanimated" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

            <h1 id={styles.heading}>Animators</h1>
            <table>
                <tbody>
                    <tr><td className={styles.creditName}>Wilton Lander</td><td className={styles.creditSocial}>@wilton.anim</td></tr>
                    <tr><td className={styles.creditName}>Rita Kara</td><td className={styles.creditSocial}>@ritakaracreative, ritak.com.au</td></tr>
                    <tr><td className={styles.creditName}>Poppy Owens</td><td className={styles.creditSocial}>@poppy_owens</td></tr>
                    <tr><td className={styles.creditName}>Ali Mai</td><td className={styles.creditSocial}>@blububu03, @BlubTheDrowningFish</td></tr>
                    <tr><td className={styles.creditName}>Michael Kenny</td><td className={styles.creditSocial}>@kenilem</td></tr>
                    <tr><td className={styles.creditName}>Benjamin Mansur</td><td className={styles.creditSocial}>@benjaminmansurart</td></tr>
                    <tr><td className={styles.creditName}>Andrew Brown</td><td className={styles.creditSocial}>@upsidebrown, andrewbrownanimator.com</td></tr>
                    <tr><td className={styles.creditName}>Katherine Le</td><td className={styles.creditSocial}>@kat_apult, @kat.apult_</td></tr>
                    <tr><td className={styles.creditName}>Isabella Spagnolo </td><td className={styles.creditSocial}>@ladybugbells</td></tr>
                    <tr><td className={styles.creditName}>Jimoen Stallard</td><td className={styles.creditSocial}>@jimen_salad</td></tr>
                    <tr><td className={styles.creditName}>Will Savage</td><td className={styles.creditSocial}>@artwithsavage</td></tr>
                    <tr><td className={styles.creditName}>Oscar Ramos Ramudo</td><td className={styles.creditSocial}>@ogbogs_art</td></tr>
                    <tr><td className={styles.creditName}>Elena Le</td><td className={styles.creditSocial}>@vinaelenaa</td></tr>
                    <tr><td className={styles.creditName}>Stewart McQueen</td><td className={styles.creditSocial}>@mcqueendraws, stewartmcqueen.weebly.com</td></tr>
                    <tr><td className={styles.creditName}>Connie Williams</td><td className={styles.creditSocial}>@spindly.cow</td></tr>
                    <tr><td className={styles.creditName}>Tanapol Suriyophasakon</td><td className={styles.creditSocial}>@tanzury</td></tr>
                    <tr><td className={styles.creditName}>Mitch Hearn</td><td className={styles.creditSocial}>@mitchographer</td></tr>
                    <tr><td className={styles.creditName}>Nicholas Lazo</td><td className={styles.creditSocial}>@nicklazoart</td></tr>
                    <tr><td className={styles.creditName}>David Goodman</td><td className={styles.creditSocial}>@mousetrap7005</td></tr>
                    <tr><td className={styles.creditName}>Maia dG</td><td className={styles.creditSocial}>@anotherfreakingartist, @MdGanimation</td></tr>
                    <tr><td className={styles.creditName}>Joel Wise</td><td className={styles.creditSocial}>@joelwize_art</td></tr>
                    <tr><td className={styles.creditName}>Xinyuan Chen</td><td className={styles.creditSocial}>@toy.hen</td></tr>
                    <tr><td className={styles.creditName}>Bengt Xylander</td><td className={styles.creditSocial}>@bengt.illustration</td></tr>
                    <tr><td className={styles.creditName}>Eugene Chung</td><td className={styles.creditSocial}>@eugeniusnius</td></tr>
                    <tr><td className={styles.creditName}>Amira Stanbouli</td><td className={styles.creditSocial}>@amira_stanbouli</td></tr>
                    <tr><td className={styles.creditName}>Emily Campbell-Doan</td><td className={styles.creditSocial}>@emilycdoan_animation</td></tr>
                    <tr><td className={styles.creditName}>Jasmine Lai</td><td className={styles.creditSocial}>@jastea009</td></tr>
                    <tr><td className={styles.creditName}>Wil Kenny</td><td className={styles.creditSocial}>@cuck.nuts</td></tr>
                    <tr><td className={styles.creditName}>Saif Khan</td></tr>
                    <tr><td className={styles.creditName}>Jemima Brooks</td><td className={styles.creditSocial}>@tusky_mima</td></tr>
                    <tr><td className={styles.creditName}>Chegg!</td><td className={styles.creditSocial}>@cheggimus</td></tr>
                    <tr><td className={styles.creditName}>Amy Djakovic</td><td className={styles.creditSocial}>@vicious_hues, @vicious_hues_art</td></tr>
                    <tr><td className={styles.creditName}>Jay De Leon Lagman</td><td className={styles.creditSocial}>@saturjaysunjay</td></tr>
                    <tr><td className={styles.creditName}>Benjamin Meyer</td><td className={styles.creditSocial}>@benspr0utt</td></tr>
                    <tr><td className={styles.creditName}>Tyler Patten</td><td className={styles.creditSocial}>@niceguytye</td></tr>
                    <tr><td className={styles.creditName}>Siann Lau</td><td className={styles.creditSocial}>@ladysiann </td></tr>
                    <tr><td className={styles.creditName}>Alex Banks</td><td className={styles.creditSocial}>@captain_omelette</td></tr>
                    <tr><td className={styles.creditName}>Katrina</td><td className={styles.creditSocial}>@tinytako</td></tr>
                    <tr><td className={styles.creditName}>Tanya</td><td className={styles.creditSocial}>@frozenspoots</td></tr>
                    <tr><td className={styles.creditName}>Summer Lloyd-Johnson</td><td className={styles.creditSocial}>@miragemeld</td></tr>
                    <tr><td className={styles.creditName}>Alea Trinajstic</td><td className={styles.creditSocial}>@alea.png, aleatrina.myportfolio.com</td></tr>
                    <tr><td className={styles.creditName}>Alice Nguyen</td><td className={styles.creditSocial}>@applebuutt</td></tr>
                    <tr><td className={styles.creditName}>SunSmog</td><td className={styles.creditSocial}>sunsmog.carrd.co</td></tr>
                    <tr><td className={styles.creditName}>Tim Fontes</td><td className={styles.creditSocial}>@emptypixl</td></tr>
                    <tr><td className={styles.creditName}>Anthony Thanaxay</td><td className={styles.creditSocial}>@hyodoko</td></tr>
                    <tr><td className={styles.creditName}>Emiko Seita </td><td className={styles.creditSocial}>@mercuryandscry_, @mercury-and-scry</td></tr>
                    <tr><td className={styles.creditName}>Angie Ngo</td><td className={styles.creditSocial}>@banjotempo</td></tr>
                    <tr><td className={styles.creditName}>Austen Armstrong</td><td className={styles.creditSocial}>@a.armstrong.photos</td></tr>
                    <tr><td className={styles.creditName}>Beatrice Rose Robinson</td><td className={styles.creditSocial}>@beatriceroserobinson, beatriceroserobinson.com</td></tr>
                    <tr><td className={styles.creditName}>Gabby Hawkey</td><td className={styles.creditSocial}>@chaiwhisker, chaiwhisker.com</td></tr>
                    <tr><td className={styles.creditName}>Angelica Phanekham </td><td className={styles.creditSocial}>@angi3hearti3_art</td></tr>
                    <tr><td className={styles.creditName}>Finnosho</td><td className={styles.creditSocial}>@finnosho</td></tr>
                    <tr><td className={styles.creditName}>Jed Summers</td><td className={styles.creditSocial}>@jedsumm, @jedsumm_</td></tr>
                    <tr><td className={styles.creditName}>Bianca Trani</td><td className={styles.creditSocial}>@bbianca.jpg</td></tr>
                    <tr><td className={styles.creditName}>Vi-An Nguyen</td><td className={styles.creditSocial}>@tulipfin</td></tr>
                    <tr><td className={styles.creditName}>Franki Furnell</td><td className={styles.creditSocial}>@flopbears</td></tr>
                    <tr><td className={styles.creditName}>jamm</td><td className={styles.creditSocial}>jarofjamm.com, @jarofjamm, @jarofjammdraws</td></tr>
                    <tr><td className={styles.creditName}>Olivia Hartanto</td><td className={styles.creditSocial}>@PrincessAndTheToad7302</td></tr>
                    <tr><td className={styles.creditName}>Kym Checa</td><td className={styles.creditSocial}>kymcheca.com</td></tr>
                    <tr><td className={styles.creditName}>Brienna McKenzie</td><td className={styles.creditSocial}>@juice_popps </td></tr>
                    <tr><td className={styles.creditName}>Courtney Spencer</td><td className={styles.creditSocial}>@arvemis, arvemis.com</td></tr>
                    <tr><td className={styles.creditName}>Lachlan Garrity</td><td className={styles.creditSocial}>@lachlangarrity</td></tr>
                    <tr><td className={styles.creditName}>Timothy Merks</td><td className={styles.creditSocial}>@timmerks</td></tr>
                    <tr><td className={styles.creditName}>Devon Xu</td><td className={styles.creditSocial}>@devxoid</td></tr>
                    <tr><td className={styles.creditName}>Maxwell Sharpe</td><td className={styles.creditSocial}>@max_r3lax, @max_r3lax_3D</td></tr>
                    <tr><td className={styles.creditName}>Nathalie Feodora</td><td className={styles.creditSocial}>@redmageatelier</td></tr>
                    <tr><td className={styles.creditName}>Nikita Sutedjo</td><td className={styles.creditSocial}>@akitales</td></tr>
                    <tr><td className={styles.creditName}>Marieka Walsh</td><td className={styles.creditSocial}>mariekawalsh.com.au</td></tr>
                    <tr><td className={styles.creditName}>Nusha Wheeldon</td><td className={styles.creditSocial}>@yeahnyah </td></tr>
                    <tr><td className={styles.creditName}>Matt Watson</td><td className={styles.creditSocial}>mattwatsonillustration.com</td></tr>
                    <tr><td className={styles.creditName}>The Treehouse</td><td className={styles.creditSocial}>@thetreehouse_animation, the-treehouse.co</td></tr>
                    <tr><td className={styles.creditName}>Waheeda Rahman-Mair</td><td className={styles.creditSocial}>@waheeda_art, waheeda.co.uk</td></tr>
                    <tr><td className={styles.creditName}>Chris Rahman-Mair</td><td className={styles.creditSocial}>@crm_camera</td></tr>
                    <tr><td className={styles.creditName}>Tim Sta-Ana</td><td className={styles.creditSocial}>@timstaana</td></tr>
                    <tr><td className={styles.creditName}>Olga Chciherova</td><td className={styles.creditSocial}>@chimyen, @birdiedraws</td></tr>
                    <tr><td className={styles.creditName}>Bryce Pemberton</td><td className={styles.creditSocial}>@bpembs</td></tr>
                    <tr><td className={styles.creditName}>Lester</td><td className={styles.creditSocial}>@lstrchn</td></tr>
                    <tr><td className={styles.creditName}>Adam Gillespie</td><td className={styles.creditSocial}>@artofadamgillespie</td></tr>
                    <tr><td className={styles.creditName}>Tonia Ciccone</td><td className={styles.creditSocial}>@toniaciccone_art</td></tr>
                    <tr><td className={styles.creditName}>Crystal Nguyen</td><td className={styles.creditSocial}>@cytnguyen</td></tr>
                    <tr><td className={styles.creditName}>Shannimation</td><td className={styles.creditSocial}>@shannanimation_bone</td></tr>
                    <tr><td className={styles.creditName}>Sohun Bae</td><td className={styles.creditSocial}>@heonb22, heonbee.weebly.com</td></tr>
                    <tr><td className={styles.creditName}>Lucy Bell</td><td className={styles.creditSocial}>@gooseyspen</td></tr>
                    <tr><td className={styles.creditName}>Wonsun Jin</td><td className={styles.creditSocial}>@wonsunjin</td></tr>
                    <tr><td className={styles.creditName}>Theo S Dahlstrom</td><td className={styles.creditSocial}>@theo.s.d</td></tr>
                    <tr><td className={styles.creditName}>Eleanor Face</td><td className={styles.creditSocial}>@fatc.hele</td></tr>
                    <tr><td className={styles.creditName}>Marshella Gohan</td><td className={styles.creditSocial}>@marziee.02</td></tr>
                    <tr><td className={styles.creditName}>Viggo Uno</td><td className={styles.creditSocial}>@viggouno</td></tr>
                    <tr><td className={styles.creditName}>James Lindsay</td><td className={styles.creditSocial}>@jmslndsy, jameslinds.com</td></tr>
                    <tr><td className={styles.creditName}>Tilli Merten</td><td className={styles.creditSocial}>@tilli_mrtn</td></tr>
                    <tr><td className={styles.creditName}>Marc agosta</td><td className={styles.creditSocial}>@marcjaarlz</td></tr>
                    <tr><td className={styles.creditName}>Svanna Christiansen</td><td className={styles.creditSocial}>@swoonc.art</td></tr>
                    <tr><td className={styles.creditName}>Sen</td><td className={styles.creditSocial}>@sen_blades, senblades.tumblr.com</td></tr>
                    <tr><td className={styles.creditName}>Jimmy Soligo</td><td className={styles.creditSocial}>@curryricenstuff</td></tr>
                    <tr><td className={styles.creditName}>Nilufar Shah</td><td className={styles.creditSocial}>@nilufar.exe</td></tr>
                    <tr><td className={styles.creditName}>Jacob Taylor</td><td className={styles.creditSocial}>@jakemakesthings</td></tr>
                    <tr><td className={styles.creditName}>Paddy O'Brien</td><td className={styles.creditSocial}>@dolphino_media, paddyobrienanimation.com</td></tr>
                    <tr><td className={styles.creditName}>Tom Groenestyn</td><td className={styles.creditSocial}>@tom_groenestyn, @tomgeez_, tomgroenestyn.com </td></tr>
                    <tr><td className={styles.creditName}>Jon Snow </td><td className={styles.creditSocial}>@synnwren</td></tr>
                    <tr><td className={styles.creditName}>Alexandra Tretiak</td><td className={styles.creditSocial}>alexandratretiak.com, @alexandratretiak</td></tr>
                    <tr><td className={styles.creditName}>Steve Do</td><td className={styles.creditSocial}>@advicecatt</td></tr>
                    <tr><td className={styles.creditName}>Ricardo Gómez</td><td className={styles.creditSocial}>@ricardo_gomez_art</td></tr>
                    <tr><td className={styles.creditName}>William Xu</td><td className={styles.creditSocial}>@w_xuart</td></tr>
                    <tr><td className={styles.creditName}>Elana Kwong</td><td className={styles.creditSocial}>@nightpest</td></tr>
                    <tr><td className={styles.creditName}>Julia van Oppen</td><td className={styles.creditSocial}>@lukalunar</td></tr>
                    <tr><td className={styles.creditName}>sailorgouda</td><td className={styles.creditSocial}>sailorgouda.newgrounds.com</td></tr>
                    <tr><td className={styles.creditName}>Johnny Pratthana</td><td className={styles.creditSocial}>iohmy.carrd.co, @iohmy_</td></tr>
                    <tr><td className={styles.creditName}>Winston Liu</td><td className={styles.creditSocial}>@pakofever</td></tr>
                    <tr><td className={styles.creditName}>pikkentt</td><td className={styles.creditSocial}>@pikkentt</td></tr>
                    <tr><td className={styles.creditName}>Han Nguyen</td><td className={styles.creditSocial}>@aeitchen</td></tr>
                    <tr><td className={styles.creditName}>Klara Mae</td><td className={styles.creditSocial}>@telemartus</td></tr>
                    <tr><td className={styles.creditName}>William Ng</td><td className={styles.creditSocial}>@nowilltodraw</td></tr>
                    <tr><td className={styles.creditName}>Axel Lawrence Nygaard</td><td className={styles.creditSocial}>axelaw-art.com, @axe_law</td></tr>
                    <tr><td className={styles.creditName}>Daze</td><td className={styles.creditSocial}>@dazefrilly</td></tr>
                    <tr><td className={styles.creditName}>Yuna Jang</td><td className={styles.creditSocial}>@silentaccio</td></tr>
                    <tr><td className={styles.creditName}>Gabriel Robinson</td><td className={styles.creditSocial}>gabrielrobinson.com, @gaberobinsonfilm</td></tr>
                    <tr><td className={styles.creditName}>Robin Eide</td><td className={styles.creditSocial}>@robineide_art, @robeans_art</td></tr>
                    <tr><td className={styles.creditName}>Chloe Lambley</td><td className={styles.creditSocial}>@clamb_art</td></tr>
                    <tr><td className={styles.creditName}>Chloe Spanos</td><td className={styles.creditSocial}>@kitschky</td></tr>
                    <tr><td className={styles.creditName}>Andrew Leonard</td><td className={styles.creditSocial}>@artworksbyandy</td></tr>
                    <tr><td className={styles.creditName}>Gerry Lin</td><td className={styles.creditSocial}>@gerrylin</td></tr>
                    <tr><td className={styles.creditName}>Holly May Fletcher</td><td className={styles.creditSocial}>@mawok </td></tr>
                    <tr><td className={styles.creditName}>Diego Luna </td><td className={styles.creditSocial}>@dmoona26</td></tr>
                    <tr><td className={styles.creditName}>Charlene Ding</td><td className={styles.creditSocial}>charleneding.com, @_charleneding_</td></tr>
                    <tr><td className={styles.creditName}>Nathaniel Lym </td><td className={styles.creditSocial}>@artsylym</td></tr>
                    <tr><td className={styles.creditName}>Jacqueline Qin (@flumazee)</td><td className={styles.creditSocial}>@flumazee</td></tr>
                    <tr><td className={styles.creditName}>Nik Sutila</td><td className={styles.creditSocial}>@mrnik_art</td></tr>
                    <tr><td className={styles.creditName}>Atticus Gough</td><td className={styles.creditSocial}>@atti.ink</td></tr>
                    <tr><td className={styles.creditName}>Brandon Tannoury</td><td className={styles.creditSocial}>@brandont_look_at_this</td></tr>
                    <tr><td className={styles.creditName}>Alyssa Mullen</td><td className={styles.creditSocial}>@heygremlin</td></tr>
                    <tr><td className={styles.creditName}>Haidi Yan</td><td className={styles.creditSocial}>@sleepistar</td></tr>
                    <tr><td className={styles.creditName}>Noura Hijazi</td><td className={styles.creditSocial}>@noura.h.art</td></tr>
                    <tr><td className={styles.creditName}>Em Brennan (Scribe_less)</td><td className={styles.creditSocial}>@scribe_less, @scribeless</td></tr>
                    <tr><td className={styles.creditName}>Kate Darwell</td><td className={styles.creditSocial}>@wiile.s</td></tr>
                    <tr><td className={styles.creditName}>vivien chiu</td><td className={styles.creditSocial}>@livelytangent</td></tr>
                    <tr><td className={styles.creditName}>Hero Hendel</td><td className={styles.creditSocial}>@h.chumiti </td></tr>
                    <tr><td className={styles.creditName}>Christopher Salloum</td><td className={styles.creditSocial}>@art.salloum, @salloumart</td></tr>
                    <tr><td className={styles.creditName}>Saachi</td><td className={styles.creditSocial}>@angels_changeling, saachio.wixsite.com/website</td></tr>
                    <tr><td className={styles.creditName}>Khoo Kai Ning</td><td className={styles.creditSocial}>@khoo_kn, khoo_kn.artstation.com</td></tr>
                    <tr><td className={styles.creditName}>Luis Lagman</td><td className={styles.creditSocial}>@pio_hag</td></tr>
                    <tr><td className={styles.creditName}>Amritha Rajesh</td><td className={styles.creditSocial}>@k.a.c.h.e.w</td></tr>
                    <tr><td className={styles.creditName}>Sebester</td><td className={styles.creditSocial}>@sebsm_campos, @sebester</td></tr>
                    <tr><td className={styles.creditName}>Shayla Nguyen</td><td className={styles.creditSocial}>@cinnavee</td></tr>
                    <tr><td className={styles.creditName}>Fransisca "Lyn" Wijaya</td><td className={styles.creditSocial}>@nyolusz</td></tr>
                    <tr><td className={styles.creditName}>Marcy Davies</td><td className={styles.creditSocial}>@westerndevilmedia_</td></tr>
                    <tr><td className={styles.creditName}>Joe Idosaka</td><td className={styles.creditSocial}>@jidska, joeidosaka.com </td></tr>
                    <tr><td className={styles.creditName}>Jessica Edge</td><td className={styles.creditSocial}>superredhead.com @super_redhead</td></tr>
                    <tr><td className={styles.creditName}>Henry Lund</td><td className={styles.creditSocial}>@henry_lund_art</td></tr>
                    <tr><td className={styles.creditName}>Vittorio Suhendro</td><td className={styles.creditSocial}>@vitz.art</td></tr>
                    <tr><td className={styles.creditName}>Olivia stewart</td><td className={styles.creditSocial}>@ArkleAnim</td></tr>
                    <tr><td className={styles.creditName}>Brandon Pen</td><td className={styles.creditSocial}>@armisaelani</td></tr>
                    <tr><td className={styles.creditName}>Jasper Ly</td><td className={styles.creditSocial}>@dapperjap</td></tr>
                    <tr><td className={styles.creditName}>Sean Liao</td><td className={styles.creditSocial}>@art_of_osprie</td></tr>
                    <tr><td className={styles.creditName}>Diogo Alves</td><td className={styles.creditSocial}>@diogomdalves</td></tr>
                    <tr><td className={styles.creditName}>Daisy Allen</td><td className={styles.creditSocial}>@daisymaisysart</td></tr>
                    <tr><td className={styles.creditName}>Miles Mazzocato </td><td className={styles.creditSocial}>@milesmazzocato</td></tr>
                    <tr><td className={styles.creditName}>Jasmine Auchterlonie</td><td className={styles.creditSocial}>@jasmine_auc</td></tr>
                    <tr><td className={styles.creditName}>blackjingertee</td><td className={styles.creditSocial}>@blackjingertee</td></tr>
                    <tr><td className={styles.creditName}>Liam Waterhouse</td><td className={styles.creditSocial}>@blinkingmanually</td></tr>
                    <tr><td className={styles.creditName}>Remi-Rose</td><td className={styles.creditSocial}>@remrose_anim</td></tr>
                    <tr><td className={styles.creditName}>billy rout</td><td className={styles.creditSocial}>@bilb0h</td></tr>
                    <tr><td className={styles.creditName}>Trotsworth</td><td className={styles.creditSocial}>@thetrotsworth</td></tr>
                    <tr><td className={styles.creditName}>Mauricio Cesana</td><td className={styles.creditSocial}>@inspirandom, inspirandom.com</td></tr>
                    <tr><td className={styles.creditName}>Heather-Renay "Nezu" Williamee</td><td className={styles.creditSocial}>@williamee.dessinee</td></tr>
                </tbody>
            </table>
            <div>
                <img className={styles.screencapL} src={screenCap0} style={{bottom: "70%"}}></img>
                <img className={styles.screencapR} src={screenCap1} style={{bottom: "62%"}}></img>
                <img className={styles.screencapL} src={screenCap2} style={{bottom: "54%"}}></img>
                <img className={styles.screencapR} src={screenCap3} style={{bottom: "46%"}}></img>
                <img className={styles.screencapL} src={screenCap4} style={{bottom: "38%"}}></img>
                <img className={styles.screencapR} src={screenCap5} style={{bottom: "30%"}}></img>
                <img className={styles.screencapL} src={screenCap6} style={{bottom: "22%"}}></img>
                <img className={styles.screencapR} src={screenCap7} style={{bottom: "14%"}}></img>
            </div>
            
            <div id={stylesMonkey.ImageParent}>
                <img id={stylesMonkey.gangImage }src={gangImg} alt="Gang"></img>
                <img id={stylesMonkey.catFishImage }src={catFishImg} alt="Catfish"></img>
                <img id={stylesMonkey.volcanoImage} src={volcano} alt="Volcano"></img>
            </div>
            
            
            <Footer/>
        </>
    )
}

export default Monkey;