import React, { useEffect, useRef, useState } from "react";
import styles from '../CSS/Dropdown.module.css';
import navStyles from '../CSS/Navbar.module.css';
import DropdownMenu from "./DropdownMenu";

const Dropdown = (props) => {
    const [open, setOpen] = useState(false)
    const dropdownRef = useRef(null)

    const handleClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
    
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    return (
        <div ref={dropdownRef}>
            <DropdownMenu isOpen={open}/>
            <a className={navStyles.inactive} href="#" onClick={() => setOpen(!open)}>
                Projects
            </a>

        </div>
    )
}

export default Dropdown