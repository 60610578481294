import React from "react";
import Navbar from '../Components/Navbar';
import styles from '../CSS/PreviousReanimated.module.css';
import spiderGun from '../Pages/Media/spidergun.jpg';
import spiderVillain from '../Pages/Media/spidervillain.jpg';
import banner from '../Pages/Media/spiderman banner.png';
import Footer from "../Components/Footer";

import screenCap0 from '../Pages/Media/Screencaps/JapaneseSpiderman/S005_Lester.png'
import screenCap1 from '../Pages/Media/Screencaps/JapaneseSpiderman/S124_AliceNguyen.png'
import screenCap2 from '../Pages/Media/Screencaps/JapaneseSpiderman/S131_Kashfi_Mannan.png'
import screenCap3 from '../Pages/Media/Screencaps/JapaneseSpiderman/S227_LucyBubalo.png'
import screenCap4 from '../Pages/Media/Screencaps/JapaneseSpiderman/S312_EvoAgus.png'
import screenCap5 from '../Pages/Media/Screencaps/JapaneseSpiderman/S322_AshNicholls.png'
import screenCap6 from '../Pages/Media/Screencaps/JapaneseSpiderman/S408_EricLieu.png'
import screenCap7 from '../Pages/Media/Screencaps/JapaneseSpiderman/S418_Winston.png'
import screenCap8 from '../Pages/Media/Screencaps/JapaneseSpiderman/S431_BriennaMcKenzie.png'

const JapaneseSpiderman = () => {
    // document.body.style = 'background-image: linear-gradient(#3A6FC1, 90%, #7991B4)';

    return(
        <>
            <Navbar/>

            <img id={styles.banner} src={banner} alt="Banner for japanese spiderman reanimated" draggable={false}></img>
            <iframe className={styles.video} id={styles.spiderVid} src="https://www.youtube.com/embed/2lyMk0HrC8w?si=DiuIPaXQQNHr8X5n" title="Japanese Spiderman Reanimated" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

            <h1 id={styles.heading}>Animators</h1>
            <table>
                <tbody>
                    <tr><td className={styles.creditName}>Wilton Lander</td><td className={styles.creditSocial}>@wilton.anim</td></tr>
                    <tr><td className={styles.creditName}>Lachlan Garrity</td><td className={styles.creditSocial}>@lachlangarrity</td></tr>
                    <tr><td className={styles.creditName}>Connie Williams</td><td className={styles.creditSocial}>@spindly.cow</td></tr>
                    <tr><td className={styles.creditName}>David Goodman</td><td className={styles.creditSocial}>@mousetrap7005</td></tr>
                    <tr><td className={styles.creditName}>Lester Chan</td><td className={styles.creditSocial}>@lstrchn</td></tr>
                    <tr><td className={styles.creditName}>Tony Chen</td><td className={styles.creditSocial}>@toy.hen</td></tr>
                    <tr><td className={styles.creditName}>Jasmine Lai</td><td className={styles.creditSocial}>@jastea009</td></tr>
                    <tr><td className={styles.creditName}>Paddy O'Brien</td><td className={styles.creditSocial}>@dolphino_media</td></tr>
                    <tr><td className={styles.creditName}>Johnny Pratthana</td><td className={styles.creditSocial}>@iohmy_</td></tr>
                    <tr><td className={styles.creditName}>Lyn Wijaya</td><td className={styles.creditSocial}>@nyolusz</td></tr>
                    <tr><td className={styles.creditName}>Mark Numan</td><td className={styles.creditSocial}>@animarkr</td></tr>
                    <tr><td className={styles.creditName}>Jamm</td><td className={styles.creditSocial}>jarofjamm.com</td></tr>
                    <tr><td className={styles.creditName}>Shayne</td></tr>
                    <tr><td className={styles.creditName}>Jemima Brooks</td><td className={styles.creditSocial}>@Tusky_Mima</td></tr>
                    <tr><td className={styles.creditName}>Remi Evans</td><td className={styles.creditSocial}>@remrose_anim</td></tr>
                    <tr><td className={styles.creditName}>Isabella Spagnolo</td><td className={styles.creditSocial}>@isabella.spagnolo</td></tr>
                    <tr><td className={styles.creditName}>Austen Armstrong</td><td className={styles.creditSocial}>@ozL00</td></tr>
                    <tr><td className={styles.creditName}>Julia van Oppen</td><td className={styles.creditSocial}>@lukalunar</td></tr>
                    <tr><td className={styles.creditName}>Maia de Graaff</td><td className={styles.creditSocial}>@anotherfreakingartist</td></tr>
                    <tr><td className={styles.creditName}>Kate Darwell</td><td className={styles.creditSocial}>@wiile.s</td></tr>
                    <tr><td className={styles.creditName}>Maia Longhurst</td></tr>
                    <tr><td className={styles.creditName}>Bochan Yang</td><td className={styles.creditSocial}>@dinklebank</td></tr>
                    <tr><td className={styles.creditName}>Kirralee Shay</td><td className={styles.creditSocial}>@kirrated</td></tr>
                    <tr><td className={styles.creditName}>Alex Banks</td><td className={styles.creditSocial}>@captain_omelette</td></tr>
                    <tr><td className={styles.creditName}>Haemin Park</td><td className={styles.creditSocial}>@haemin_parki</td></tr>
                    <tr><td className={styles.creditName}>Alyssa Mullen</td><td className={styles.creditSocial}>@heygremlin</td></tr>
                    <tr><td className={styles.creditName}>Andrew Leonard</td><td className={styles.creditSocial}>@artworksbyandy</td></tr>
                    <tr><td className={styles.creditName}>Andy Lin</td><td className={styles.creditSocial}>@wrathrarts</td></tr>
                    <tr><td className={styles.creditName}>Crystal nguyen</td><td className={styles.creditSocial}>@cytnguyen</td></tr>
                    <tr><td className={styles.creditName}>Jolynne Sourn</td><td className={styles.creditSocial}>@jolynnesourn</td></tr>
                    <tr><td className={styles.creditName}>Alice Nguyen</td><td className={styles.creditSocial}>@applebuutt</td></tr>
                    <tr><td className={styles.creditName}>Evan Billings</td><td className={styles.creditSocial}>@Evan75224481</td></tr>
                    <tr><td className={styles.creditName}>Brandon Doray</td><td className={styles.creditSocial}>@brandon.m.doray</td></tr>
                    <tr><td className={styles.creditName}>Klara Norris-O'Neill</td><td className={styles.creditSocial}>@telemartus</td></tr>
                    <tr><td className={styles.creditName}>Atticus Gough</td><td className={styles.creditSocial}>@atti.ink</td></tr>
                    <tr><td className={styles.creditName}>Dirtynoodles</td><td className={styles.creditSocial}>@dirtynoodles </td></tr>
                    <tr><td className={styles.creditName}>Devon Xu</td><td className={styles.creditSocial}>@devxoid</td></tr>
                    <tr><td className={styles.creditName}>Kashfi Mannan</td><td className={styles.creditSocial}>@kashm0ni</td></tr>
                    <tr><td className={styles.creditName}>Tom Groenestyn</td><td className={styles.creditSocial}>@tom_groenestyn</td></tr>
                    <tr><td className={styles.creditName}>Tim Fontes</td><td className={styles.creditSocial}>@emptypixl</td></tr>
                    <tr><td className={styles.creditName}>Eleanor Face</td><td className={styles.creditSocial}>@eleanorface_</td></tr>
                    <tr><td className={styles.creditName}>Alex H-H</td><td className={styles.creditSocial}>@Sirens_Virus</td></tr>
                    <tr><td className={styles.creditName}>Benjamin Meyer</td><td className={styles.creditSocial}>@benspr0utt</td></tr>
                    <tr><td className={styles.creditName}>Tonia Ciccone</td><td className={styles.creditSocial}>@toniaciccone_art</td></tr>
                    <tr><td className={styles.creditName}>Nathaniel Lym</td><td className={styles.creditSocial}>@artsylym</td></tr>
                    <tr><td className={styles.creditName}>Brandon Pen</td><td className={styles.creditSocial}>@armisaelani</td></tr>
                    <tr><td className={styles.creditName}>William Cho</td><td className={styles.creditSocial}>@jch_1198</td></tr>
                    <tr><td className={styles.creditName}>Olivia Stewart</td><td className={styles.creditSocial}>@ArkleAnim</td></tr>
                    <tr><td className={styles.creditName}>Haidi Yan</td><td className={styles.creditSocial}>@sleepistar</td></tr>
                    <tr><td className={styles.creditName}>Nathan Le</td><td className={styles.creditSocial}>@enel_animations</td></tr>
                    <tr><td className={styles.creditName}>Em Brennan</td><td className={styles.creditSocial}>@scribe_less @scribeless</td></tr>
                    <tr><td className={styles.creditName}>Diego Luna</td><td className={styles.creditSocial}>@dmoona26</td></tr>
                    <tr><td className={styles.creditName}>Beatrice Robinson</td><td className={styles.creditSocial}>@beatriceroserobinson</td></tr>
                    <tr><td className={styles.creditName}>Meg Strachan</td><td className={styles.creditSocial}>@justmegstrak</td></tr>
                    <tr><td className={styles.creditName}>Rachel Wotton</td><td className={styles.creditSocial}>@twiddlinghumbugs</td></tr>
                    <tr><td className={styles.creditName}>Nathalie Feodora</td><td className={styles.creditSocial}>@chevrefee</td></tr>
                    <tr><td className={styles.creditName}>Amira Stanbouli </td><td className={styles.creditSocial}>@amira_stanbouli</td></tr>
                    <tr><td className={styles.creditName}>Caroline Zijing</td><td className={styles.creditSocial}>@daily_crispy</td></tr>
                    <tr><td className={styles.creditName}>Gab Milner</td><td className={styles.creditSocial}>@gabaaaart</td></tr>
                    <tr><td className={styles.creditName}>Daisy Allen</td><td className={styles.creditSocial}>@daisymaisysart</td></tr>
                    <tr><td className={styles.creditName}>Naifah Alam</td><td className={styles.creditSocial}>@beepop_uwu</td></tr>
                    <tr><td className={styles.creditName}>Han Nguyen</td><td className={styles.creditSocial}>@aeitchen</td></tr>
                    <tr><td className={styles.creditName}>David Muir</td><td className={styles.creditSocial}>@davidsolm_art</td></tr>
                    <tr><td className={styles.creditName}>Yuna Jang</td><td className={styles.creditSocial}>@silentaccio (ig)</td></tr>
                    <tr><td className={styles.creditName}>Lucy Bubalo</td><td className={styles.creditSocial}>@spiderhammock</td></tr>
                    <tr><td className={styles.creditName}>Chloe Lambley</td><td className={styles.creditSocial}>@koolkitty100</td></tr>
                    <tr><td className={styles.creditName}>Tanapol Suriyophasakon</td><td className={styles.creditSocial}>@tanzury</td></tr>
                    <tr><td className={styles.creditName}>Angelica Phanekham</td><td className={styles.creditSocial}>@arti3hearti3</td></tr>
                    <tr><td className={styles.creditName}>Nikita Sutedjo</td><td className={styles.creditSocial}>@akitales</td></tr>
                    <tr><td className={styles.creditName}>Zachary Zitis</td></tr>
                    <tr><td className={styles.creditName}>HonShing Cheung (Michael)</td><td className={styles.creditSocial}>@azure_chs @AAAzure_1</td></tr>
                    <tr><td className={styles.creditName}>Vivien Chiu</td><td className={styles.creditSocial}>@livelytangent</td></tr>
                    <tr><td className={styles.creditName}>Maxine Gando Flores</td><td className={styles.creditSocial}>@maxinedanielle</td></tr>
                    <tr><td className={styles.creditName}>Wonsun Jin</td><td className={styles.creditSocial}>@wonsunjin</td></tr>
                    <tr><td className={styles.creditName}>Christopher Salloum</td><td className={styles.creditSocial}>@art.salloum</td></tr>
                    <tr><td className={styles.creditName}>Bengt Xylander</td><td className={styles.creditSocial}>@bengt.illustration</td></tr>
                    <tr><td className={styles.creditName}>Pedro Caffé</td><td className={styles.creditSocial}>@pedrocaffem</td></tr>
                    <tr><td className={styles.creditName}>Henry Lund</td><td className={styles.creditSocial}>@henry_lund_art</td></tr>
                    <tr><td className={styles.creditName}>Amy Djakovic</td><td className={styles.creditSocial}>@vicioushues_art</td></tr>
                    <tr><td className={styles.creditName}>Saachi Owen</td></tr>
                    <tr><td className={styles.creditName}>Evo Agus</td><td className={styles.creditSocial}>@evoagus </td></tr>
                    <tr><td className={styles.creditName}>Gabriel Robinson</td><td className={styles.creditSocial}>@gabriel.robinson91</td></tr>
                    <tr><td className={styles.creditName}>Emily Li</td></tr>
                    <tr><td className={styles.creditName}>Tilli Merten</td><td className={styles.creditSocial}>@tilli_mrtn</td></tr>
                    <tr><td className={styles.creditName}>Steve Do</td><td className={styles.creditSocial}>@advicecatt</td></tr>
                    <tr><td className={styles.creditName}>Maxwell Sharpe</td><td className={styles.creditSocial}>@max_r3lax</td></tr>
                    <tr><td className={styles.creditName}>Gene Tibig</td><td className={styles.creditSocial}>@sploosh.art</td></tr>
                    <tr><td className={styles.creditName}>Nusha Wheeldon</td><td className={styles.creditSocial}>@yeahnyah</td></tr>
                    <tr><td className={styles.creditName}>ash nicholls</td><td className={styles.creditSocial}>Tokillarocking1</td></tr>
                    <tr><td className={styles.creditName}>Tim Sta-Ana</td><td className={styles.creditSocial}>@timstaana</td></tr>
                    <tr><td className={styles.creditName}>Ankit Singh</td><td className={styles.creditSocial}>@hokuelrod </td></tr>
                    <tr><td className={styles.creditName}>Marshella Gohan</td><td className={styles.creditSocial}>@marziee.02</td></tr>
                    <tr><td className={styles.creditName}>Theo Dahlstrom</td><td className={styles.creditSocial}>@theo.s.d</td></tr>
                    <tr><td className={styles.creditName}>Molly Hoad</td></tr>
                    <tr><td className={styles.creditName}>Trotsworth</td><td className={styles.creditSocial}>@TheTrotsworth</td></tr>
                    <tr><td className={styles.creditName}>Emily Hestelow</td><td className={styles.creditSocial}>@emily_hestelow</td></tr>
                    <tr><td className={styles.creditName}>Jacob Taylor</td><td className={styles.creditSocial}>@jakemakesthings</td></tr>
                    <tr><td className={styles.creditName}>Eric Lieu</td><td className={styles.creditSocial}>@eric_lieu</td></tr>
                    <tr><td className={styles.creditName}>Jeremy Le</td><td className={styles.creditSocial}>@jeremykiinn</td></tr>
                    <tr><td className={styles.creditName}>Elena Le</td><td className={styles.creditSocial}>@vinaelenaa</td></tr>
                    <tr><td className={styles.creditName}>Luis Lagman</td><td className={styles.creditSocial}>@pio_hag</td></tr>
                    <tr><td className={styles.creditName}>Finnian O'Conor</td><td className={styles.creditSocial}>@goodoldfinn</td></tr>
                    <tr><td className={styles.creditName}>Shayla Nguyen</td><td className={styles.creditSocial}>@cinnavee</td></tr>
                    <tr><td className={styles.creditName}>Joel Wise</td><td className={styles.creditSocial}>@joelwize_art</td></tr>
                    <tr><td className={styles.creditName}>Sean Liao</td><td className={styles.creditSocial}>@art_of_osprie</td></tr>
                    <tr><td className={styles.creditName}>Jasper Ly</td><td className={styles.creditSocial}>@japperderp</td></tr>
                    <tr><td className={styles.creditName}>Winston Liu</td><td className={styles.creditSocial}>@pakofever</td></tr>
                    <tr><td className={styles.creditName}>Miles Mazzocato</td><td className={styles.creditSocial}>@milesmazzocato</td></tr>
                    <tr><td className={styles.creditName}>Aurelius Sutilo</td><td className={styles.creditSocial}>@aurelius.jpeg</td></tr>
                    <tr><td className={styles.creditName}>Tanya Upkhonova</td><td className={styles.creditSocial}>@frozenspoots</td></tr>
                    <tr><td className={styles.creditName}>Narah O</td><td className={styles.creditSocial}>@before_3am</td></tr>
                    <tr><td className={styles.creditName}>Amy Yehong Chen</td><td className={styles.creditSocial}>@amychenie</td></tr>
                    <tr><td className={styles.creditName}>Tyler Patten</td><td className={styles.creditSocial}>@Niceguytye</td></tr>
                    <tr><td className={styles.creditName}>Jay De Leon Lagman</td><td className={styles.creditSocial}>@saturjaysunjay</td></tr>
                    <tr><td className={styles.creditName}>Ren Davies</td><td className={styles.creditSocial}>@r3ndvis</td></tr>
                    <tr><td className={styles.creditName}>Eugene Chung </td><td className={styles.creditSocial}>@eugeniusnius</td></tr>
                    <tr><td className={styles.creditName}>Eumene Kim</td><td className={styles.creditSocial}>@yumLn_</td></tr>
                    <tr><td className={styles.creditName}>Brienna McKenzie</td><td className={styles.creditSocial}>@juice_popps</td></tr>
                </tbody>
            </table>
            <div>
                <img className={styles.screencapL} src={screenCap0} style={{bottom: "70%"}}></img>
                <img className={styles.screencapR} src={screenCap1} style={{bottom: "62%"}}></img>
                <img className={styles.screencapL} src={screenCap2} style={{bottom: "54%"}}></img>
                <img className={styles.screencapR} src={screenCap3} style={{bottom: "46%"}}></img>
                <img className={styles.screencapL} src={screenCap4} style={{bottom: "38%"}}></img>
                <img className={styles.screencapR} src={screenCap5} style={{bottom: "30%"}}></img>
                <img className={styles.screencapL} src={screenCap6} style={{bottom: "22%"}}></img>
                <img className={styles.screencapR} src={screenCap7} style={{bottom: "14%"}}></img>
                <img className={styles.screencapL} src={screenCap8} style={{bottom: "6%"}}></img>
            </div>

            <Footer/>
        </>
    )
}

export default JapaneseSpiderman;