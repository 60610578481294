import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from '../CSS/Dropdown.module.css';

const DropdownItem = (props) => {

    return (
        <div className={styles.menuItem}>
            {props.icon} 
            <NavLink
                        to={`/${props.name}`}
                        className={({ isActive }) => (isActive ? styles.active : styles.inactive)}
                    >
                        &nbsp;&nbsp;&nbsp;{props.year}
            </NavLink>
        </div>
    )
}

export default DropdownItem