import { Container } from 'react-bootstrap';
import profileStyles from '../CSS/Profile.module.css'

function Profile({name, social, mingus, color, color2, link}) {
    return(
        <>
            <div className={profileStyles.profileContainer}>
                <img className={profileStyles.profileImage} style={{backgroundColor: color, border: color2 + ' solid 2px'}} src={mingus}></img>
                <h3 className={profileStyles.profileName}>{name}</h3>
                <p className={profileStyles.profileText}><a className={profileStyles.profileTextLink} href={link}>{social}</a></p>

            </div>
        </>   
    )
}
export default Profile;

