import React from "react";
import { NavLink } from "react-router-dom";
import styles from '../CSS/Navbar.module.css';
import Dropdown from '../Components/Dropdown';
// import DropdownItem from "./DropdownItem";
import DropdownMenu from "./DropdownMenu";

const Navbar = () => {
    return(
        <div id={styles.navbar}>
            <h1 id={styles.header}>
                <NavLink
                    to="/"
                    id={styles.reanimated}
                >
                    REANIMATED.AU
                </NavLink>
            </h1>

            <div id={styles.navBtns}>
                <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? styles.active : styles.inactive)}
                    id={styles.home}
                    >
                    Home
                </NavLink>

                <NavLink
                    to="/about"
                    className={({ isActive }) => (isActive ? styles.active : styles.inactive)}
                    id={styles.about}
                    >
                    About
                </NavLink>

                <Dropdown/>

            </div>
                
        </div>
    )
}

export default Navbar;