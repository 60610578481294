import React, { useState } from "react";
import styles from '../CSS/Dropdown.module.css';
import DropdownItem from "./DropdownItem";

const DropdownMenu = (props) => {
    return (
        // Can easily change name and icon in dropdown or just not have an icon
        <div className={`${styles.dropdownMenu} ${props.isOpen ? styles.open : styles.close}`}>
            { <DropdownItem name='Monkey' year='2024' icon='🐒'/> }
            <DropdownItem name='JapaneseSpiderman' year='2023' icon='🕷️'/>
            <DropdownItem name='Batman' year='2022' icon='🦇'/>
        </div>
    )
}

export default DropdownMenu